import Vue from "vue";
import Vuex from "vuex";
import gql from "graphql-tag";
import { apolloClient } from "../plugins/apollo";
import * as Sentry from "@sentry/browser";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    next_route: null,
    user: {
      token: null,
      is_login: false,
      id: null,
      acl: null,
      approved: null,
      is_loading: true,
      first_name: "",
      last_name: "",
      email: "",
    },
    create_seminar: {},
    zlt_users: [],
    current_annuity: {
      is_from_reload: false,
      has_pdf: false,
      carrier_list: [],
      carrier_name: "",
      product_list: [],
      product_name: "",
      description: "",
      asset_type: "va",
      mne: 0,
      admin: 0,
      expense_ratio: 0,
      additional_fees: 0,
      current_value: "",
      original_value: "",
      issue_date: "",
      issue_month: 0, //computed
      issue_year: 0, //computed
      statement_date: "",
      statement_month: 0, //computed
      statement_year: 0, //computed
      free_wd_percent: 10,
      first_year_free_wd_percent: 10,
      free_wd_type: "account_value",
      qualified: false,
      total_surrender_dollar: "",
      is_income_rider: false,
      total_withdrawals_since_statement: "",
      additional_wds_prior_transfer: 0,
      income_rider_amount: "",
      total_wds: "",
      surrender_years: 0,
      surrender_values: [],
      income_start_age: 0,
      income_account_value: 0,
      valid: false,
    },
    client_qualified_na: {
      bonus_percentage: 0,
      inflation_percentage: 0,
      interest_rate: 7,
      withdrawal_type: "rmd",
      start_age: 70,
      end_age: 95,
      withdrawal_amount: 0,
      carrier_name: "",
      product_name: "",
      fee: 0,
      valid: false,
    },
    client_nonqualified_na: {
      bonus_percentage: 5,
      inflation_percentage: 0,
      interest_rate: 7,
      withdrawal_type: "growth",
      start_age: 70,
      end_age: 95,
      withdrawal_amount: 0,
      carrier_name: "",
      product_name: "",
      fee: 0,
      valid: false,
    },
    zlt_form: {
      submitted: false, // This is act as the handler for the form
      meta: {
        is_from_reload: false,
        db_reloading_complete: false,
        db_reloading: false,
        owner_id: null,
        owner_name: null,
        report_id: null,
      },
      client: {
        le: 0,
        state: "",
        current_age: "",
        gender: "male",
        name: "",
        valid: false,
      },
      current_annuities: [],
      qualified: {
        bonus_percentage: 0,
        inflation_percentage: 0,
        interest_rate: 7,
        withdrawal_type: "rmd",
        start_age: 70,
        end_age: 95,
        withdrawal_amount: 0,
        carrier_name: "",
        product_name: "",
        fee: 0,
        valid: false,
      },
      non_qualified: {
        bonus_percentage: 5,
        inflation_percentage: 0,
        interest_rate: 7,
        withdrawal_type: "growth",
        start_age: 70,
        end_age: 95,
        withdrawal_amount: 0,
        carrier_name: "",
        product_name: "",
        fee: 0,
        valid: false,
      },
    },
    zlt_output: {},
    morningstar_setup: false,
    morningstar_data: [],
    snackbar: {
      show: false,
      text: "",
    },
  },
  mutations: {
    set_search_type(state, type) {
      state.create_seminar.final_search_type = type;
    },
    reset_create_seminar(state) {
      state.create_seminar = {
        step: 1,
        pricing: 0.52,
        campaign_id: null, // temp 1670
        venueName: null,
        venueAddress: null, // used for autocomplete state
        street: null,
        city: null,
        state: null,
        zip: null, // THIS IS THE VENUE ZIP CODE
        range: 6,
        search_type: "radius", // zip or radius
        final_search_type: "unset",
        lat: 0,
        lng: 0,
        zips: [],
        email_disclosure: "",
        book_disclosure: "",
        appointment_dates: [],
        restaurantValue: null,
        min_event_date_offset: 1000 * 60 * 60 * 24 * 28,
        events: [
          {
            date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 28)
              .toISOString()
              .slice(0, 10),
            time: "6:30 pm",
            capacity: null,
            aptDate1: null,
            aptDate2: null,
            aptDate3: null,
            aptDate4: null,
          },
        ],
        contentTemplate: null,
        contentTemplateId: null,
        background: "Steak Dinner",
        final_price: 0,
        demographics: {
          distance: 30,
          income: 50000,
          income_type: "income",
          search_query: "",
          ipa: null,
          minAge: 65,
          maxAge: 79,
          platelicker_enabled: false,
          number_of_records: 0,
          requested_count: 0,
          request_id: null,
          ethnicityChanged: false,
          ethnicityList: [
            { selected: true, text: "African American", value: "A" },
            { selected: true, text: "East Asian", value: "B" },
            { selected: true, text: "Central & Southwest Asian", value: "D" },
            { selected: true, text: "Mediterranean", value: "E" },
            { selected: true, text: "Native American", value: "F" },
            { selected: true, text: "Scandinavian", value: "G" },
            { selected: true, text: "Polynesian", value: "H" },
            { selected: true, text: "Middle Eastern", value: "I" },
            { selected: true, text: "Jewish", value: "J" },
            { selected: true, text: "Western European", value: "K" },
            { selected: true, text: "Eastern European", value: "L" },
            { selected: true, text: "Other Groups", value: "O" },
            { selected: true, text: "Hispanic", value: "Y" },
            // ### OLD ###
            // { selected: true, text: "African", value: "D" },
            // { selected: true, text: "African American", value: "B" },
            // { selected: true, text: "Native American", value: "I" },
            // { selected: true, text: "Arab", value: "E" },
            // { selected: true, text: "Asian", value: "A" },
            // { selected: true, text: "Caucasian", value: "W" },
            // { selected: true, text: "Chinese", value: "C" },
            // { selected: true, text: "Dutch", value: "L" },
            // { selected: true, text: "Eastern European", value: "S" },
            // { selected: true, text: "Egyptian", value: "F" },
            // { selected: true, text: "English", value: "O" },
            // { selected: true, text: "European", value: "Q" },
            // { selected: true, text: "Greek", value: "G" },
            // { selected: true, text: "Hispanic", value: "H" },
            // { selected: true, text: "Indian", value: "K" },
            // { selected: true, text: "Japanese", value: "N" },
            // { selected: true, text: "Jewish", value: "J" },
            // { selected: true, text: "East Asian", value: "R" },
            // { selected: true, text: "Portuguese", value: "P" },
            // { selected: true, text: "White Non-American", value: "M" },
          ],
        },
        language: null,
      };
    },
    set_current_annuity(state, payload) {
      state.current_annuity = {
        ...payload,
        is_from_reload: true,
        carrier_list: [],
        product_list: [],
        issue_month: 0, //computed
        issue_year: 0, //computed
        statement_month: 0, //computed
        statement_year: 0, //computed
      };
    },
    create_seminar_address(state, payload) {
      state.create_seminar.street = payload.name;
      state.create_seminar.city = payload.locality;
      state.create_seminar.state = payload.administrative_area_level_1;
      state.create_seminar.zip = payload.postal_code;
      state.create_seminar.lat = payload.latitude;
      state.create_seminar.lng = payload.longitude;
    },
    reset_zlt_form(state) {
      state.zlt_form = {
        submitted: false, // This is act as the handler for the form
        meta: {
          is_from_reload: false,
          db_reloading_complete: false,
          db_reloading: false,
          owner_id: null,
          report_id: null,
        },
        client: {
          le: 0,
          state: "",
          current_age: "",
          gender: "male",
          name: "",
          valid: false,
        },
        current_annuities: [],
        qualified: {
          bonus_percentage: 0,
          inflation_percentage: 0,
          interest_rate: 7,
          withdrawal_type: "rmd",
          start_age: 70,
          end_age: 95,
          withdrawal_amount: 0,
          carrier_name: "",
          product_name: "",
          fee: 0,
          valid: false,
        },
        non_qualified: {
          bonus_percentage: 5,
          inflation_percentage: 0,
          interest_rate: 7,
          withdrawal_type: "growth",
          start_age: 70,
          end_age: 95,
          withdrawal_amount: 0,
          carrier_name: "",
          product_name: "",
          fee: 0,
          valid: false,
        },
      };
      this.commit("add_another_asset");
    },
    reset_carrier(state, index) {
      state.zlt_form.current_annuities[index].carrier_list = [];
      state.zlt_form.current_annuities[index].carrier_name = "";
      state.zlt_form.current_annuities[index].product_list = [];
      state.zlt_form.current_annuities[index].product_name = "";

      state.zlt_form.current_annuities[index].mne = 0;
      state.zlt_form.current_annuities[index].admin = 0;
      state.zlt_form.current_annuities[index].expense_ratio = 0;
      state.zlt_form.current_annuities[index].additional_fees = 0;
    },
    set_zlt_form_new_annuity(state, payload) {
      if (payload.qualified) {
        state.zlt_form.qualified = payload;
      } else {
        state.zlt_form.non_qualified = payload;
      }
    },
    set_zlt_ca_date(state, payload) {
      state.zlt_form.current_annuities[payload.index][payload.field] =
        payload.date;
    },
    set_zlt_form_ca_surrender_years(state, payload) {
      state.zlt_form.current_annuities[payload.index].surrender_years =
        payload.years;

      state.zlt_form.current_annuities[payload.index].surrender_values =
        state.zlt_form.current_annuities[payload.index].surrender_values.splice(
          0,
          payload.years
        );
    },

    set_snackbar(state, payload) {
      state.snackbar.show = true;
      state.snackbar.text = payload;
    },
    reset_current_annuity(state) {
      state.current_annuity = {
        is_from_reload: false,
        has_pdf: false,
        carrier_list: [],
        carrier_name: "",
        product_list: [],
        product_name: "",
        description: "",
        asset_type: "va",
        mne: 0,
        admin: 0,
        expense_ratio: 0,
        additional_fees: 0,
        current_value: "",
        original_value: "",
        issue_date: "",
        issue_month: 0, //computed
        issue_year: 0, //computed
        statement_date: "",
        statement_month: 0, //computed
        statement_year: 0, //computed
        free_wd_percent: 10,
        first_year_free_wd_percent: 10,
        free_wd_type: "account_value",
        qualified: false,
        total_surrender_dollar: "",
        is_income_rider: false,
        total_withdrawals_since_statement: "",
        additional_wds_prior_transfer: "",
        income_rider_amount: "",
        total_wds: "",
        surrender_years: 0,
        surrender_values: [],
        income_start_age: 0,
        valid: false,
      };
    },
    add_another_asset(state) {
      state.zlt_form.current_annuities.push({
        carrier_list: [],
        carrier_name: "",
        product_list: [],
        product_name: "",
        description: "",
        asset_type: "va",
        mne: 0,
        admin: 0,
        expense_ratio: 0,
        fees: 0,
        current_value: "",
        original_value: "",
        issue_date: "",
        issue_month: 0, //computed
        issue_year: 0, //computed
        statement_date: "",
        statement_month: 0, //computed
        statement_year: 0, //computed
        free_wd_percent: 10,
        first_year_free_wd_percent: 10,
        free_wd_type: "account_value",
        qualified: false,
        total_surrender_dollar: "",
        is_income_rider: false,
        total_withdrawals_since_statement: "",
        additional_wds_prior_transfer: "",
        income_rider_amount: "",
        total_wds: "",
        surrender_years: 0,
        surrender_values: [],
        income_start_age: 0,
        valid: false,
      });
    },
    remove_asset(state, index) {
      state.zlt_form.current_annuities.splice(index, 1);
    },
    setup_zlt_carrier_list(state, payload) {
      state.zlt_form.current_annuities[payload.index].carrier_list =
        payload.list;
    },
    setup_zlt_carrier(state, payload) {
      state.zlt_form.current_annuities[payload.index].carrier_name =
        payload.name;
    },
    setup_zlt_product_list(state, payload) {
      state.zlt_form.current_annuities[payload.index].product_list =
        payload.list;
    },
    setup_morningstar_data(state, payload) {
      state.morningstar_data = payload;
      state.morningstar_setup = true;
    },
    setup_zlt_from_db(state, payload) {
      console.log(payload);
      this.commit("reset_zlt_form");

      state.zlt_form.meta.owner_id = payload.user_id;
      state.zlt_form.meta.owner_name =
        payload.user.first_name + " " + payload.user.last_name;
      state.zlt_form.meta.report_id = payload.id;
      state.zlt_form.client.current_age = payload.age; // TODO: refactor current_age to be age
      state.zlt_form.client.gender = payload.gender;
      state.zlt_form.client.state = payload.state;
      state.zlt_form.client.name = payload.case_name;

      payload.zero_loss_transfer_new_annuities.forEach((na) => {
        na.withdrawal_type = na.withdrawal_type.toLowerCase();
        na.valid = false;
        if (na.qualified) {
          state.zlt_form.qualified = na;
        } else {
          state.zlt_form.non_qualified = na;
        }
      });

      state.zlt_form.current_annuities =
        payload.zero_loss_transfer_current_annuities.map((ca) => {
          console.log("ca.qualified", ca.qualified);
          return {
            carrier_list: [],
            carrier_name: ca.carrier_name,
            product_list: [],
            product_name: ca.product_name,
            description: ca.description,
            asset_type: {
              fixedIndexAnnuity: "fia",
              variableAnnuity: "va",
              userDefined: "userDefined",
              otherAsset: "otherAsset",
            }[ca.annuity_type],
            mne: ca.mne,
            admin: ca.admin,
            expense_ratio: ca.expense_ratio,
            fees: ca.additional_fees,
            current_value: ca.accumulation_value,
            original_value: ca.original_deposit,
            issue_date: ca.date_issued,
            issue_month: 0, //computed
            issue_year: 0, //computed
            statement_date: ca.statement_date,
            statement_month: 0, //computed
            statement_year: 0, //computed
            free_wd_percent: ca.free_withdrawal_amount || 10, // TODO: Fix mapping on server
            first_year_free_wd_percent: ca.first_year_free_wd_percent || 10,
            free_wd_type: ca.free_withdrawal_type, // TODO: Check type mapping
            qualified: ca.qualified || false,
            total_surrender_dollar: ca.today_surrender_dollar_amount,
            is_income_rider: ca.is_income_rider,
            total_withdrawals_since_statement:
              ca.total_withdrawals_since_statement,
            additional_wds_prior_transfer: ca.additional_wds_prior_transfer,
            income_rider_amount: ca.income_rider_amount,
            total_wds: ca.total_withdrawals,
            surrender_years: (ca.surrender_values || []).length,
            surrender_values: ca.surrender_values || [],
            income_start_age: ca.income_rider_age,
            valid: false,
          };
        }) || [];
    },
    setup_zlt_users(state, payload) {
      state.zlt_users = payload;
    },
    setup_current_user(state, payload) {
      state.user = payload;
      // state.user.email = "matt@insurmark.net";
      state.user.is_login = true;
      console.log("setup_current_user", state.user);
    },
    signout(state) {
      state.user = {
        token: null,
        is_login: false,
        id: null,
        acl: null,
        approved: null,
        first_name: "",
        last_name: "",
        email: "",
      };
      localStorage.removeItem("token");
    },
    set_next_route(state, payload) {
      state.next_route = payload;
    },
  },
  actions: {
    async getCurrentUser({ commit }) {
      let token = localStorage.getItem("token");
      if (token === null || token === "" || token === undefined) {
        commit("signout");
        return;
      }
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      let cUserObj = JSON.parse(jsonPayload);

      // expire tokens that were created before approved status
      if (cUserObj.iat * 1000 < 1634878550000) {
        console.log("token expired");
        commit("signout");
        localStorage.removeItem("token");
        return;
      }
      let user = JSON.parse(atob(cUserObj.sub));
      user.token = token;
      // if (user.email.includes("insurmark.net")) {
      //   user.approved = false;
      // }
      // TEST
      Sentry.setUser({ email: user.email, id: user.id });
      commit("setup_current_user", user);

      // get the users full state from the server
      const { data } = await apolloClient.query({
        query: gql`
          query User($user_id: Int!) {
            users_by_pk(id: $user_id) {
              acl
              active
              all_in
              annuity_premium
              approved
              approved_date
              bootcamp_status
              business_name
              city
              current_imos
              email
              first_name
              hidden
              id
              last_name
              allowed_carriers {
                carrier_id
              }
              marketer_id
              phone
              state
              status
              sticky_notes
              street
              zip
              inflationalpha_flag

              business_street
              business_city
              business_state
              business_zip

              mailing_street
              mailing_city
              mailing_state
              mailing_zip

              has_ppt
              seminar_control_training
              production_level
              mail_credit
              software_expiry

              book_cover_name
              book_cover_phone
              book_cover_email
              book_disclaimer
            }
          }
        `,
        variables: {
          user_id: user.id,
        },
      });

      commit("setup_current_user", {
        ...user,
        ...data.users_by_pk,
        // ...{
        //   acl: 0,
        // },
        ...{
          is_loading: false,
        },
      });
    },
    async load_zlt_users_from_db({ commit }) {
      const { data } = await apolloClient.query({
        query: gql`
          query AllUsers {
            users(where: { approved: { _eq: true } }) {
              first_name
              last_name
              id
            }
          }
        `,
      });

      let users = data.users.map((u) => {
        return {
          value: u.id,
          text: `${u.first_name} ${u.last_name}`,
        };
      });
      users.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });

      commit("setup_zlt_users", users);
    },
    async load_zlt_from_db({ commit, state }, payload) {
      const query = gql`
        query ZeroLossCaseByPK($id: Int!) {
          zero_loss_transfer_cases_by_pk(id: $id) {
            id
            user_id
            user {
              first_name
              last_name
            }
            age
            case_name
            gender
            state
            zero_loss_transfer_current_annuities {
              accumulation_value
              additional_fees
              additional_wds_prior_transfer
              admin
              annuity_type
              carrier_name
              date_issued
              description
              expense_ratio
              free_withdrawal_amount
              first_year_free_wd_percent
              free_withdrawal_type
              income_rider_age
              income_rider_amount
              is_income_rider
              mne
              original_deposit
              product_name
              qualified
              remaining_surrender_years
              statement_date
              surrender_values
              today_surrender_dollar_amount
              total_withdrawals
              total_withdrawals_since_statement
            }
            zero_loss_transfer_new_annuities {
              bonus_percentage
              carrier_name
              end_age
              fee
              inflation_percentage
              interest_rate
              product_name
              qualified
              start_age
              withdrawal_amount
              withdrawal_type
            }
          }
        }
      `;
      state.zlt_form.meta.db_reloading = true;
      const { data } = await apolloClient.query({
        query,
        variables: {
          id: payload,
        },
      });

      commit("setup_zlt_from_db", data.zero_loss_transfer_cases_by_pk);
      state.zlt_form.meta.is_from_reload = true;
      state.zlt_form.meta.db_reloading = false;
      state.zlt_form.meta.db_reloading_complete = true;
    },
    async save_zlt_form_to_db({ commit, state }) {
      try {
        if (state.zlt_form.meta.report_id) {
          // Update Case
          console.log("Updading Case");
          await apolloClient.mutate({
            mutation: gql`
              mutation UpdateZLTCase(
                $id: Int!
                $age: Int
                $case_name: String
                $gender: String
                $state: String
                $valid: Boolean
                $user_id: Int!
              ) {
                update_zero_loss_transfer_cases_by_pk(
                  pk_columns: { id: $id }
                  _set: {
                    age: $age
                    case_name: $case_name
                    gender: $gender
                    state: $state
                    valid: $valid
                    user_id: $user_id
                  }
                ) {
                  updated_at
                }
              }
            `,
            variables: {
              id: state.zlt_form.meta.report_id,
              age: state.zlt_form.client.current_age || null,
              case_name: state.zlt_form.client.name || "Valued Client",
              gender: state.zlt_form.client.gender || null,
              state: state.zlt_form.client.state || null,
              user_id: state.zlt_form.meta.owner_id || null,
              valid: state.zlt_form.submitted || false,
            },
          });
          // Delete Current Annuities and New Annuities
          console.log("Deleting Children");
          await apolloClient.mutate({
            mutation: gql`
              mutation DeleteZLTSubParts($zltc_id: Int!) {
                delete_zero_loss_transfer_current_annuity(
                  where: { zltc_id: { _eq: $zltc_id } }
                ) {
                  affected_rows
                }
                delete_zero_loss_transfer_new_annuity(
                  where: { zltc_id: { _eq: $zltc_id } }
                ) {
                  affected_rows
                }
              }
            `,
            variables: {
              zltc_id: state.zlt_form.meta.report_id,
            },
          });
        } else {
          // Create Case
          console.log("Creating new case");
          let result = await apolloClient.mutate({
            mutation: gql`
              mutation InsertZLTCase(
                $age: Int
                $case_name: String
                $gender: String
                $state: String
                $user_id: Int!
                $valid: Boolean
              ) {
                insert_zero_loss_transfer_cases_one(
                  object: {
                    age: $age
                    case_name: $case_name
                    gender: $gender
                    state: $state
                    user_id: $user_id
                    valid: $valid
                  }
                ) {
                  id
                }
              }
            `,
            variables: {
              age: state.zlt_form.client.current_age || null,
              case_name: state.zlt_form.client.name || "Valued Client",
              gender: state.zlt_form.client.gender || null,
              state: state.zlt_form.client.state || null,
              user_id: state.zlt_form.meta.owner_id || null,
              valid: state.zlt_form.submitted || false,
            },
          });
          state.zlt_form.meta.report_id =
            result.data.insert_zero_loss_transfer_cases_one.id;
          console.log("Created new case");
          console.log(result);
        }
        // Now the new and update should be in the same state
        // step 2: save zlt_current_annuities to db
        let ca = state.zlt_form.current_annuities.map((annuity) => {
          console.log(annuity);
          return {
            accumulation_value: annuity.current_value || null,
            additional_fees: annuity.fees || null,
            additional_wds_prior_transfer:
              annuity.additional_wds_prior_transfer || null,
            admin: annuity.admin || null,
            annuity_type:
              {
                fia: "fixedIndexAnnuity",
                va: "variableAnnuity",
                userDefined: "userDefined",
                otherAsset: "otherAsset",
              }[annuity.asset_type] || null,
            carrier_name: annuity.carrier_name,
            date_issued: annuity.issue_date,
            description: annuity.description,
            expense_ratio: annuity.expense_ratio || null,
            free_withdrawal_type: annuity.free_wd_type || null,
            free_withdrawal_amount: annuity.free_wd_percent || null, // TOOD: Fix this on the server
            first_year_free_wd_percent:
              annuity.first_year_free_wd_percent || null,
            income_rider_age: annuity.income_start_age || null,
            income_rider_amount: annuity.income_rider_amount || null,
            is_income_rider: annuity.is_income_rider,
            original_deposit: annuity.original_value || null,
            product_name: annuity.product_name || null,
            qualified: annuity.qualified,
            remaining_surrender_years: annuity.surrender_years || null,
            statement_date: annuity.statement_date || null,
            surrender_values: `{${annuity.surrender_values.join(",")}}`,
            today_surrender_dollar_amount:
              annuity.total_surrender_dollar || null,
            total_withdrawals: annuity.total_wds || null,
            total_withdrawals_since_statement:
              annuity.total_withdrawals_since_statement || null,
            zltc_id: state.zlt_form.meta.report_id,
          };
        });
        let na = [state.zlt_form.qualified, state.zlt_form.non_qualified].map(
          (annuity, index) => {
            console.log(annuity.withdrawal_amount);
            return {
              bonus_percentage: annuity.bonus_percentage,
              carrier_name: annuity.carrier_name,
              end_age: annuity.end_age,
              fee: annuity.fee,
              inflation_percentage: annuity.inflation_percentage,
              interest_rate: annuity.interest_rate,
              product_name: annuity.product_name,
              qualified: index == 0 ? true : false,
              start_age: annuity.start_age,
              withdrawal_amount: +annuity.withdrawal_amount,
              withdrawal_type: annuity.withdrawal_type,
              zltc_id: state.zlt_form.meta.report_id,
            };
          }
        );

        console.log("ZLT -> CA -> Save");
        console.log("ZLT -> NA -> Save");
        await apolloClient.mutate({
          mutation: gql`
            mutation InsertZLTCA(
              $ca: [zero_loss_transfer_current_annuity_insert_input!]!
              $na: [zero_loss_transfer_new_annuity_insert_input!]!
            ) {
              insert_zero_loss_transfer_current_annuity(objects: $ca) {
                returning {
                  id
                }
              }
              insert_zero_loss_transfer_new_annuity(objects: $na) {
                returning {
                  id
                }
              }
            }
          `,
          variables: {
            ca: ca,
            na: na,
          },
        });
        return true;
      } catch (e) {
        console.log(e);
        commit("set_snackbar", "Error Saving to DB");
        return false;
      }

      // step 3: save zlt_new_annuities to db
    },
    async setup_morningstar_data({ commit }) {
      const query = gql`
        query MorningStar {
          annuities {
            admin_fee
            me_fee
            expense_ratio
            annuity_type
            company_name
            policy_name
            surrender
          }
        }
      `;
      const { data } = await apolloClient.query({ query });
      commit("setup_morningstar_data", data.annuities);
    },
  },
  getters: {
    user_allowed(state) {
      console.log(
        state.user.is_login,
        state.user.is_loading,
        state.user.approved,
        state.user.software_expiry
      );

      if (state.user.is_loading || state.user.is_loading === undefined) {
        return "LOADING";
      }
      if (state.user.approved) {
        return true;
      }
      if (
        state.user.software_expiry &&
        new Date(state.user.software_expiry) >= new Date()
      ) {
        return true;
      }
      return false;
    },
  },
  modules: {},
});
