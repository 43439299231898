<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" fixed temporary dark right clipped>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-list-item>
            <v-img
              alt="AnnuityCheck"
              class="mr-2 mt-5 mb-5"
              contain
              src="@/assets/ac-logo-dark.png"
              transition="scale-transition"
              width="120"
            />
          </v-list-item>

          <v-list-item
            link
            v-for="(tab, index) in $store.state.user.is_login
              ? loggedInMenu
              : loggedOutMenu"
            :key="index"
            :to="tab.custom_action ? '' : tab.to"
            @click.stop="tab.custom_action ? tab.custom_action() : null"
          >
            <v-list-item-icon>
              <v-icon>{{ tab.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ tab.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-if="$store.state.user.acl === 10">
            <v-divider class="mt-2 mb-2"></v-divider>
            <h3>Admin</h3>
            <v-list-item
              link
              v-for="(tab, index) in admin"
              :key="index"
              :to="tab.to"
            >
              <v-list-item-icon>
                <v-icon>{{ tab.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ tab.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark color="#303030">
      <div
        class="d-flex align-center"
        style="height: 100%"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <router-link to="/">
          <v-img
            alt="AnnuityCheck"
            class="mr-2"
            contain
            src="@/assets/ac-logo-dark.png"
            transition="scale-transition"
            width="220"
            height="100%"
          />
        </router-link>
      </div>
      <div class="d-flex align-center" style="height: 100%" v-else>
        <router-link to="/" style="height: 100%">
          <v-img
            alt="AnnuityCheck"
            contain
            width="50"
            style="height: 100%"
            src="@/assets/ac-logo-short.png"
            transition="scale-transition"
          />
        </router-link>
      </div>
      <v-divider
        v-if="
          'software_expiry' in $store.state.user && !$store.state.user.approved
        "
        style="background-color: white; margin-left: 15px"
        vertical
        inset
      ></v-divider>
      <div
        v-if="
          'software_expiry' in $store.state.user && !$store.state.user.approved
        "
        class="d-flex align-center"
        style="height: 100%; margin-left: 15px; width: 600px"
      >
        <!-- Do not delete this, it makes the loading more seamless -->
        <p v-if="!('software_expiry' in $store.state.user)"></p>
        <!-- Case: IMO Access -->
        <p style="margin: 0" v-else-if="$store.state.user.approved"></p>
        <!-- Case: New User -->
        <p style="margin: 0" v-else-if="!$store.state.user.software_expiry">
          Welcome to AnnuityCheck! Order a Campaign to get started.
        </p>
        <!-- Case: Non IMO User, software expired -->
        <p
          style="margin: 0; color: white; font-weight: bold"
          v-else-if="new Date($store.state.user.software_expiry) <= new Date()"
        >
          Software Expired
        </p>
        <!-- Case: Non IMO User, software not expired -->
        <p
          style="margin: 0"
          v-else-if="new Date($store.state.user.software_expiry) >= new Date()"
        >
          <span style="font-weight: bold; color: #c8ffb8">
            Software Active.
          </span>
          Days Remaining:
          {{ days_remaining() }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndDown"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-tabs right optional v-else v-model="tab">
        <v-tab
          v-for="(tab, index) in $store.state.user.is_login
            ? loggedInMenu
            : loggedOutMenu"
          :key="index"
          :to="tab.custom_action ? '' : tab.to"
          @click.stop="tab.custom_action ? tab.custom_action() : null"
        >
          {{ tab.text }}
        </v-tab>
        <v-menu
          bottom
          offset-y
          open-on-hover
          v-if="$store.state.user.acl === 10"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="align-self-center mr-4" v-bind="attrs" v-on="on">
              Admin
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              v-for="(tab, index) in admin"
              :key="index"
              :to="tab.to"
            >
              <v-list-item-content>
                <v-list-item-title> {{ tab.text }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tabs>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar v-model="$store.state.snackbar.show">
      {{ $store.state.snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="$store.state.snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "App",
  created() {},
  mounted() {
    if (localStorage?.dark === "true") {
      this.$vuetify.theme.dark = true;
      localStorage.dark = "true";
    } else {
      this.$vuetify.theme.dark = false;
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);
  },
  methods: {
    signout() {
      this.$store.commit("signout");
      // using href here to clear state
      window.location.href = "/";
    },
    days_remaining() {
      return (
        dayjs(this.$store.state.user.software_expiry).diff(Date.now(), "day") +
        1
      );
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
    $route(to) {
      this.tab = null;
      this.$nextTick(() => {
        this.tab = to;
      });

      // if (to.path === "/calculators") {
      //   this.tab = null;
      //   this.$nextTick(() => {
      //     this.tab = "/calculators";
      //   });
      // }
      // if (to.path === "/store") {
      //   this.tab = null;
      //   this.$nextTick(() => {
      //     this.tab = "/store";
      //   });
      // }
      // if (to.path.includes("admin")) {
      //   this.$nextTick(() => {
      //     this.tab = null;
      //   });
      // }
    },
  },
  data() {
    return {
      logo_prefix: "ac",
      company_name: "AnnuityCheck",
      tab: null,
      drawer: false,
      group: null,
      admin: [
        {
          text: "Agents",
          icon: "mdi-account",
          to: "/admin/agents",
        },
        {
          text: "Cases",
          icon: "mdi-finance",
          to: "/admin/proposals",
        },
        {
          text: "Campaigns",
          icon: "mdi-email-newsletter",
          to: "/admin/seminars",
        },
        {
          text: "Bootcamp Events",
          icon: "mdi-calendar",
          to: "/admin/events",
        },
      ],
      loggedOutMenu: [
        {
          text: "Home",
          icon: "mdi-home",
          to: "/",
        },
        // {
        //   text: "Store",
        //   icon: "mdi-storefront",
        //   to: "/store",
        // },
        {
          text: "Sign In",
          icon: "mdi-login",
          to: "/login",
        },
      ],
      loggedInMenu: [
        {
          text: "Home",
          icon: "mdi-home",
          to: "/",
        },
        // {
        //   text: "Store",
        //   icon: "mdi-storefront",
        //   to: "/store",
        // },
        // {
        //   text: "Seminar Control",
        //   icon: "mdi-school-outline",
        //   to: "/seminar-control",
        // },
        // {
        //   text: "Cases",
        //   icon: "mdi-calculator",
        //   to: "/client",
        // },
        {
          text: "Campaigns",
          icon: "mdi-email-newsletter",
          to: "/seminar",
        },
        {
          text: "Account",
          icon: "mdi-account",
          to: "/account",
        },
        {
          text: "Sign Out",
          icon: "mdi-logout",
          to: "/",
          custom_action: this.signout,
        },
      ],
      //
    };
  },
};
</script>
