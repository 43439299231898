import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// eslint-disable-next-line no-unused-vars
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/annuityscore",
    name: "AnnuityScore",
    component: () =>
      import(
        /* webpackChunkName: "AnnuityScore" */ "../views/AnnuityScore.vue"
      ),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/About.vue"),
  },
  {
    path: "/video/:id",
    name: "VideoPlayer",
    component: () =>
      import(/* webpackChunkName: "VideoPlayer" */ "../views/VideoPlayer.vue"),
  },
  // {
  //   path: "/seminar-control",
  //   name: "SeminarControl",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "SeminarControl" */ "../views/SeminarControl.vue"
  //     ),
  // },
  {
    path: "/video-new",
    name: "VideoNew",
    component: () =>
      import(/* webpackChunkName: "VideoNew" */ "../views/VideoNew.vue"),
  },
  {
    path: "/video-edit/:id",
    name: "VideoEdit",
    component: () =>
      import(/* webpackChunkName: "VideoEdit" */ "../views/VideoEdit.vue"),
  },
  // {
  //   path: "/store",
  //   name: "Store",
  //   component: () =>
  //     import(/* webpackChunkName: "Router" */ "../views/store/Router.vue"),
  //   children: [
  //     {
  //       path: "",
  //       name: "Home",
  //       component: () =>
  //         import(/* webpackChunkName: "StoreHome" */ "../views/store/Home.vue"),
  //     },
  //     {
  //       path: ":id",
  //       name: "StoreDetail",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "StoreDetail" */ "../views/store/Product.vue"
  //         ),
  //     },
  //     {
  //       path: "complete/:type",
  //       name: "StoreComplete",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "StoreComplete" */ "../views/store/Complete.vue"
  //         ),
  //     },
  //   ],
  // },
  // {
  //   path: "/oto",
  //   alias: ["/eca", "/internal_offer"],
  //   name: "oto",
  //   component: () =>
  //     import(/* webpackChunkName: "OTO_Router" */ "../views/oto/Router.vue"),
  //   children: [
  //     {
  //       path: "",
  //       name: "Real",
  //       component: () =>
  //         import(/* webpackChunkName: "OTO_Real" */ "../views/oto/Real.vue"),
  //     },
  //     {
  //       path: "signin",
  //       name: "oto_signin",
  //       component: () =>
  //         import(/* webpackChunkName: "OTO_Signin" */ "../views/oto/Login.vue"),
  //     },
  //     {
  //       path: "signup",
  //       name: "oto_signup",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "OTO_Signup" */ "../views/oto/Register.vue"
  //         ),
  //     },
  //     {
  //       path: "checkout",
  //       name: "oto_checkout",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "OTO_Checkout" */ "../views/oto/Checkout.vue"
  //         ),
  //     },
  //     {
  //       path: "expired",
  //       name: "oto_expired",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "OTO_Expired" */ "../views/oto/Expired.vue"
  //         ),
  //     },
  //   ],
  // },

  {
    path: "/presentation",
    name: "Presentation",
    component: () =>
      import(
        /* webpackChunkName: "Presentation" */ "../views/Presentation.vue"
      ),
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import(/* webpackChunkName: "Account" */ "../views/Account.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/auth/Login.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () =>
      import(/* webpackChunkName: "SignUp" */ "../views/auth/SignUp.vue"),
  },
  // {
  //   path: "/bootcamp",
  //   name: "Bootcamp",
  //   component: () =>
  //     import(/* webpackChunkName: "Bootcamp" */ "../views/auth/Bootcamp.vue"),
  // },
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ "../views/auth/PasswordReset.vue"
      ),
  },
  {
    path: "/reset-password/:token",
    name: "UpdatePassword",
    component: () =>
      import(
        /* webpackChunkName: "UpdatePassword" */ "../views/auth/UpdatePassword.vue"
      ),
  },
  {
    path: "/seminar",
    name: "Seminar",
    component: () =>
      import(/* webpackChunkName: "Router" */ "../views/seminar/Router.vue"),
    children: [
      // {
      //   path: "create",
      //   name: "CreateSeminar",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "CreateSeminar" */ "../views/seminar/CreateSeminar.vue"
      //     ),
      // },
      {
        path: "success",
        name: "SeminarComplete",
        component: () =>
          import(
            /* webpackChunkName: "SeminarComplete" */ "../views/seminar/SeminarComplete.vue"
          ),
      },
      {
        path: "",
        name: "ListSeminars",
        component: () =>
          import(
            /* webpackChunkName: "ListSeminars" */ "../views/seminar/ListSeminars.vue"
          ),
      },
      {
        path: ":id",
        name: "SeminarDetails",
        component: () =>
          import(
            /* webpackChunkName: "SeminarDetails" */ "../views/seminar/SeminarDetails.vue"
          ),
      },
      // {
      //   path: "households",
      //   name: "ListHouseholds",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "ListHouseholds" */ "../views/seminar/ListHouseholds.vue"
      //     ),
      // },
      {
        path: "household/:id",
        name: "HouseholdDetail",
        component: () =>
          import(
            /* webpackChunkName: "HouseholdDetail" */ "../components/Seminar/HouseholdDetail.vue"
          ),
      },
    ],
  },
  // {
  //   path: "/client",
  //   name: "Client",
  //   component: () =>
  //     import(/* webpackChunkName: "Router" */ "../views/client/Router.vue"),
  //   children: [
  //     {
  //       path: "",
  //       name: "ListClients",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "ListClients" */ "../views/client/ListClients.vue"
  //         ),
  //     },
  //     {
  //       path: ":id",
  //       name: "ClientDetails",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "ClientDetails" */ "../views/client/ClientDetails.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/current_annuity",
  //       name: "CurrentAnnuity",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "CurrentAnnuity" */ "../views/client/CurrentAnnuityForm.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/current_annuity/:ca_id",
  //       name: "CurrentAnnuityReload",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "CurrentAnnuityReload" */ "../views/client/CurrentAnnuityForm.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/current_annuity/:ca_id/report",
  //       name: "CurrentAnnuityReport",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "CurrentAnnuityReport" */ "../views/client/CurrentAnnuityReport.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/new_annuity/:report_id/report",
  //       name: "NewAnnuityReport",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "NewAnnuityReport" */ "../views/client/TransferReport.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/new_annuity",
  //       name: "NewAnnuityManageForm",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "NewAnnuityManageForm" */ "../views/client/NewAnnuityManageForm.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/new_annuity/:report_id",
  //       name: "NewAnnuityManageFormReload",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "NewAnnuityManageFormReload" */ "../views/client/NewAnnuityManageForm.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/withdrawal",
  //       name: "Withdrawal",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "Withdrawal" */ "../views/client/calcs/Withdrawal.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/max-wd",
  //       name: "MaxWD",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "MaxWD" */ "../views/client/calcs/MaxWD.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/wd-percent",
  //       name: "WithdrawalPercent",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "WithdrawalPercent" */ "../views/client/calcs/WithdrawalPercent.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/deposit",
  //       name: "MinDeposit",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "MinDepo" */ "../views/client/calcs/MinDepo.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/deposit_inflation",
  //       name: "MinDepositInflation",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "MinDepositInflation" */ "../views/client/calcs/MinDepoInflation.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/inflation_alpha_inflation",
  //       name: "InflationAlphaInflation",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "InflationAlphaInflation" */ "../views/client/calcs/InflationAlphaInflation.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/interest",
  //       name: "MinInterest",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "MinInterest" */ "../views/client/calcs/MinInterest.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/withdrawal/:report_id",
  //       name: "WithdrawalReload",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "Withdrawal" */ "../views/client/calcs/Withdrawal.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/max-wd/:report_id",
  //       name: "MaxWDReload",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "MaxWD" */ "../views/client/calcs/MaxWD.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/wd-percent/:report_id",
  //       name: "WithdrawalPercentReload",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "WithdrawalPercent" */ "../views/client/calcs/WithdrawalPercent.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/deposit/:report_id",
  //       name: "MinDepositReload",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "MinDepo" */ "../views/client/calcs/MinDepo.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/deposit_inflation/:report_id",
  //       name: "MinDepositInflationReload",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "MinDepositInflation" */ "../views/client/calcs/MinDepoInflation.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/inflation_alpha_inflation/:report_id",
  //       name: "InflationAlphaInflation",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "InflationAlphaInflation" */ "../views/client/calcs/InflationAlphaInflation.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/interest/:report_id",
  //       name: "MinInterestReload",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "MinDepo" */ "../views/client/calcs/MinInterest.vue"
  //         ),
  //     },
  //     {
  //       path: ":client_id/calc_result/:report_id",
  //       name: "CalcResult",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "CalcResult" */ "../views/client/calcs/Result.vue"
  //         ),
  //     },
  //     // /client/${this.client.id}/calc_result/${id}
  //     // {
  //     //   path: ":client_id/new_annuity/:ca_id/report",
  //     //   name: "CurrentAnnuityReport",
  //     //   component: () =>
  //     //     import(
  //     //       /* webpackChunkName: "CurrentAnnuity" */ "../views/client/CurrentAnnuityReport.vue"
  //     //     ),
  //     // },
  //   ],
  // },
  // {
  //   path: "/calculators",
  //   name: "CalculatorsRouter",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "CalculatorRouter" */ "../views/calculators/Router.vue"
  //     ),
  //   children: [
  //     {
  //       path: "",
  //       name: "CalculatorsHome",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "CalculatorHome" */ "../views/calculators/Home.vue"
  //         ),
  //     },
  //     {
  //       path: "proposal",
  //       name: "Proposal",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "Proposal" */ "../views/calculators/Proposal.vue"
  //         ),
  //     },
  //     {
  //       path: "proposal/:id",
  //       name: "ProposalWithID",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "Proposal" */ "../views/calculators/Proposal.vue"
  //         ),
  //     },
  //     {
  //       path: "snapshot",
  //       name: "Snapshot",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "Snapshot" */ "../views/calculators/Snapshot.vue"
  //         ),
  //     },
  //     {
  //       path: "withdrawal",
  //       name: "Withdrawal",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "Withdrawal" */ "../views/calculators/Withdrawal.vue"
  //         ),
  //     },
  //     {
  //       path: "max-wd",
  //       name: "MaxWD",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "MaxWD" */ "../views/calculators/MaxWD.vue"
  //         ),
  //     },
  //     {
  //       path: "wd-percent",
  //       name: "WithdrawalPercent",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "WithdrawalPercent" */ "../views/calculators/WithdrawalPercent.vue"
  //         ),
  //     },
  //     // {
  //     //   path: "deposit",
  //     //   name: "MinDeposit",
  //     //   component: () =>
  //     //     import(
  //     //       /* webpackChunkName: "MinDepo" */ "../views/calculators/MinDepo.vue"
  //     //     ),
  //     // },
  //     {
  //       path: "income-rider",
  //       name: "IncomeRider",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "IncomeRider" */ "../views/calculators/IncomeRider.vue"
  //         ),
  //     },
  //     {
  //       path: "222",
  //       name: "222",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "IncomeRider222" */ "../views/calculators/222.vue"
  //         ),
  //     },
  //     {
  //       path: "smart-max-wd",
  //       name: "SmartMaxWD",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "SmartMaxWD" */ "../views/calculators/SmartMaxWD.vue"
  //         ),
  //     },
  //     // {
  //     //   path: "smart-deposit",
  //     //   name: "SmartMinDeposit",
  //     //   component: () =>
  //     //     import(
  //     //       /* webpackChunkName: "SmartMinDepo" */ "../views/calculators/SmartMinDepo.vue"
  //     //     ),
  //     // },
  //     {
  //       path: "smart-withdrawal",
  //       name: "SmartWithdrawal",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "SmarthWithdrawal" */ "../views/calculators/SmartWithdrawal.vue"
  //         ),
  //     },
  //   ],
  // },

  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "Admin" */ "../views/admin/Admin.vue"),
    children: [
      {
        path: "agents",
        component: () =>
          import(
            /* webpackChunkName: "AdminAccounts" */ "../views/admin/Accounts.vue"
          ),
      },
      {
        path: "proposals",
        component: () =>
          import(
            /* webpackChunkName: "AdminProposals" */ "../views/admin/Proposals.vue"
          ),
      },
      {
        path: "seminars",
        component: () =>
          import(
            /* webpackChunkName: "AdminListSeminars" */ "../views/admin/ListSeminars.vue"
          ),
      },
      {
        path: "events",
        component: () =>
          import(
            /* webpackChunkName: "AdminEventList" */ "../views/admin/EventList.vue"
          ),
      },
      {
        path: "events/:event_id",
        component: () =>
          import(
            /* webpackChunkName: "AdminEventDetail" */ "../views/admin/EventDetail.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  const publicPages = ["/", "/login", "/signup", "/presentation"];

  const authRequired = !(
    publicPages.includes(to.path) ||
    to.path.search("reset-password") !== -1 ||
    to.path.search("oto") !== -1 ||
    to.path.search("eca") !== -1 ||
    to.path.search("internal_offer") !== -1 ||
    to.path.search("video") !== -1 ||
    to.path.search("store") !== -1
  );

  if (authRequired && !store.state.user.is_login) {
    store.commit("set_next_route", to.path);
    return next("/login");
  }
  if (!store.state.user.approved && to.path.includes("calculator")) {
    store.commit(
      "set_snackbar",
      "You are not currently approved.  Please contact support@annuitycheck.com if you feel this is in error"
    );
    return next("/account");
  }

  next();
});

export default router;
