import Vue from "vue";
import dayjs from "dayjs";

Vue.mixin({
  data() {
    return {
      all_states: [
        { name: "Alabama", abbreviation: "AL" },
        { name: "Alaska", abbreviation: "AK" },
        { name: "Arizona", abbreviation: "AZ" },
        { name: "Arkansas", abbreviation: "AR" },
        { name: "California", abbreviation: "CA" },
        { name: "Colorado", abbreviation: "CO" },
        { name: "Connecticut", abbreviation: "CT" },
        { name: "Delaware", abbreviation: "DE" },
        { name: "District of Colombia", abbreviation: "DC" },
        { name: "Florida", abbreviation: "FL" },
        { name: "Georgia", abbreviation: "GA" },
        { name: "Hawai'i", abbreviation: "HI" },
        { name: "Idaho", abbreviation: "ID" },
        { name: "Illinois", abbreviation: "IL" },
        { name: "Indiana", abbreviation: "IN" },
        { name: "Iowa", abbreviation: "IA" },
        { name: "Kansas", abbreviation: "KS" },
        { name: "Kentucky", abbreviation: "KY" },
        { name: "Louisiana", abbreviation: "LA" },
        { name: "Maine", abbreviation: "ME" },
        { name: "Maryland", abbreviation: "MD" },
        { name: "Massachusetts", abbreviation: "MA" },
        { name: "Michigan", abbreviation: "MI" },
        { name: "Minnesota", abbreviation: "MN" },
        { name: "Mississippi", abbreviation: "MS" },
        { name: "Missouri", abbreviation: "MO" },
        { name: "Montana", abbreviation: "MT" },
        { name: "Nebraska", abbreviation: "NE" },
        { name: "Nevada", abbreviation: "NV" },
        { name: "New Hampshire", abbreviation: "NH" },
        { name: "New Jersey", abbreviation: "NJ" },
        { name: "New Mexico", abbreviation: "NM" },
        { name: "New York", abbreviation: "NY" },
        { name: "North Carolina", abbreviation: "NC" },
        { name: "North Dakota", abbreviation: "ND" },
        { name: "Ohio", abbreviation: "OH" },
        { name: "Oklahoma", abbreviation: "OK" },
        { name: "Oregon", abbreviation: "OR" },
        { name: "Pennsylvania", abbreviation: "PA" },
        { name: "Rhode Island", abbreviation: "RI" },
        { name: "South Carolina", abbreviation: "SC" },
        { name: "South Dakota", abbreviation: "SD" },
        { name: "Tennessee", abbreviation: "TN" },
        { name: "Texas", abbreviation: "TX" },
        { name: "Utah", abbreviation: "UT" },
        { name: "Vermont", abbreviation: "VT" },
        { name: "Virginia", abbreviation: "VA" },
        { name: "Washington", abbreviation: "WA" },
        { name: "West Virginia", abbreviation: "WV" },
        { name: "Wisconsin", abbreviation: "WI" },
        { name: "Wyoming", abbreviation: "WY" },
      ],
    };
  },

  methods: {
    mailerPrice(
      og_count,
      ethnicityChanged,
      ipaChanged = false,
      has_credits = false
    ) {
      let count = og_count;
      if (has_credits && og_count >= 6000) {
        count -= 1000;
      }
      let increment = (Math.floor(+count / 1000) * 1000 - 5000) / 100000;
      let price = 0.59 - increment;
      if (price <= 0.54) price = 0.54;

      if (ethnicityChanged) {
        price += 0.02;
      }
      if (ipaChanged) {
        price += 0.03;
      }
      price = Math.round(price * 100) / 100;
      return price;
    },
    mailerTotal(
      og_count,
      ethnicityChanged,
      ipaChanged = false,
      has_credits = false
    ) {
      let price = this.mailerPrice(
        og_count,
        ethnicityChanged,
        ipaChanged,
        has_credits
      );
      if (has_credits) {
        if (og_count < 6000) {
          return price * og_count;
        } else {
          return price * (og_count - 1000);
        }
      }
      return og_count * price;
    },
    dateFormat(date) {
      let d = dayjs(date).format("M/D/YY");
      if (d === "Invalid Date") {
        return "";
      }
      return d;
    },
    csvDownload(header, data, filename) {
      /*
       * Create a CSV file from the data in the header and data arrays.
       * The header array should be an array of strings, the data array
       * should be an array of arrays of strings.
       */
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += header.join() + "\n";
      data.forEach((row) => {
        let rowString = row
          .map((r) => {
            return '"' + r + '"';
          })
          .join(",");
        csvContent += rowString + "\n";
      });
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    currencyFormat(value) {
      try {
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      } catch (error) {
        console.log(error);
        return "ERROR";
      }
    },
    numberFormat(value) {
      return value.toLocaleString("en-US");
    },
    currencyFormat2Places(value) {
      value = +value;
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    percentFormat(value) {
      return (value / 100).toLocaleString("en-US", {
        style: "percent",
        minimumFractionDigits: 2,
      });
    },
    percentFormatZero(value) {
      return (value / 100).toLocaleString("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
      });
    },
  },
});
