import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import apolloProvider from "./plugins/apollo";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// TODO: Convert to plugin
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: "AIzaSyAZpnFIkebY_b-A1R0aRmr7oESGlg2euW0",
});
console.log(process.env);
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://1963fe01b2184f09aca4fb6b268f3141@o1064500.ingest.sentry.io/6055366",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "annuitycheck.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    logErrors: true,
  });
}

// // TODO: Convert to plugin
// import Rollbar from "rollbar";
// Vue.prototype.$rollbar = new Rollbar({
//   accessToken: "c20dc37c627e4dc9b736c5c847820012",
//   captureUncaught: true,
//   captureUnhandledRejections: true,
//   environment: process.env.NODE_ENV,
//   payload: {
//     client: {
//       javascript: {
//         code_version: "1.2",
//       },
//     },
//   },
// });
// Vue.config.errorHandler = (err, vm) => {
//   vm.$rollbar.error(err);
//   throw err; // rethrow
// };

import "./plugins/vuetify-money.js";
import "./plugins/vuetify-mask.js";
import "./plugins/vue-donut.js";
import "./assets/global.css";
import "leaflet/dist/leaflet.css";

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// eslint-disable-next-line no-unused-vars
import helpers from "./mixins/helpers";

import("./services/irr_wasm/irr_wasm").then(({ internal_rate_of_return }) => {
  window.internal_rate_of_return = internal_rate_of_return;
});

Vue.config.productionTip = false;

store.dispatch("getCurrentUser");

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,

  render: (h) => h(App),
}).$mount("#app");
