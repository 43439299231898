import ApolloClient from "apollo-boost";
import VueApollo from "vue-apollo";
import Vue from "vue";

Vue.use(VueApollo);

export const apolloClient = new ApolloClient({
  uri: "https://graphql.annuitycheck.com/v1/graphql",
  headers: {
    "content-type": "application/json",
    ...(localStorage.getItem("token") && {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }),
  },
});

export default new VueApollo({
  defaultClient: apolloClient,
});
